<template>
    <v-container>
        <!-- <h1 class="headline">Conference Call</h1> -->

        <HyphenDatatable
    :headers="headers"
    :config="{resource:'video-conference/expert', title: 'Conference call', rowClickUrl:'conference-call', enabledActions: [ 'search', 'delete','hide-delete-action']}"
    />

         <!-- <v-data-table
    :headers="headers"
    :items="conferenceCallList"
    sort-by="name"
    class="elevation-1"
    @click:row="handleClick"
   :options.sync="options"
      :server-items-length="count"
      :items-per-page="10"
      :loading="loading"
  >
  <template v-slot:[`item.preferredDate`]="{ item }">
    {{formatDate(item.preferredDate, 'MMM Do YYYY hh:mm A')}}
  </template>
  <template v-slot:[`item.approvedDate`]="{ item }">
    {{formatDate(item.approvedDate, 'MMM Do YYYY hh:mm A')}}
  </template>

    <template v-slot:top>
      <v-toolbar
        flat
      >
        <v-toolbar-title>Conference Call</v-toolbar-title>
        <v-divider
          class="mx-4"
          inset
          vertical
        ></v-divider>
        <app-filter @filter-change="getConferenceCallList"/>
       
       

        

        
      </v-toolbar>
    </template>
    
    <template v-slot:no-data>
        No Data
      
    </template>
  </v-data-table> -->
    


    </v-container>
</template>



<script>
import HyphenDatatable from './component/HyphenDatatable.vue'

export default {
  components:{
    HyphenDatatable
  },

    data(){
        return{
            conferenceCallList:[],
            headers:[
                    {text: 'Id', value: 'id'},
                    {text: 'Title', value: 'title'},
                    {text: 'Inquiry Type', value: 'inquiryType'},
                    {text: 'Area', value: 'area'},
                    
                    {text: 'Preferred Date/Time', value: 'preferredDate'},
                  
                    {text: 'Approved Date/Time', value: 'approvedDate'},
                    {text: 'Customer', value: 'customer.name'},
                    {text: 'Link', value: 'link'},
                      {text: 'Remarks', value: 'remarks'},
                    {text: 'Status', value: 'status'},
            ],
              count: null,
    loading:false,
    options:{},
        }
    },
     watch: {
     options:{
       handler () {
          this.getConferenceCallList()
        },
        deep: true,
    }
  },
    methods:{
        getConferenceCallList($event){
          if($event?.search){
        this.options.search = $event.search
      }
      this.formatFilter
      if($event?.isDeleted){
        this.options.isDeleted = $event.isDeleted
      }
          this.loading = true;
            this.$General.getVideoConference(this.options).then(data=>{
                this.conferenceCallList = data.data.data.list;
                this.count = data.data.data.count;
                this.loading = false;
                }).catch(error=>{
                  this.loading = false;
                    console.log(error);
                })
        },
        handleClick(args){
            this.$router.push({path:'conference-call/'+args.id});
          console.log(args);
        //   this.$router.push({path:"/design/"+args.id});
        
        
        
      },
    },
    mounted(){
        this.getConferenceCallList();
    }
}
</script>


<style scoped>

</style>